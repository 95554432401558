import React from "react";
import { IDefaultSelectProps } from "./types";

const DefaultSelect: React.FC<IDefaultSelectProps> = ({
    name,
    options,
    value,
    selectHandler,
    field,
                                                          multiple,
    styles,
}) => {
    return (
        <div className={'select-wrapper--default'} style={styles || {}}>
            <select
                className='select'
                name={name}
                value={value}
                multiple={multiple}
                onChange={selectHandler}
            >
                {options.map((option, index) => (
                    <option key={index} data-id={option?._id} value={option.value}>
                        {option[field]}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DefaultSelect;
