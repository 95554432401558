import { ITableTitle } from "../../../../types/table";

export const Options: ITableTitle[] = [
    { type: 'header', slug: 'increment' },
    { type: 'header', slug: 'photo' },
    { type: 'header', slug: 'name' },
    { type: 'header', slug: 'price' },
    { type: 'header', slug: 'restaurant' },
    { type: 'header', slug: 'weight' },
    { type: 'header', slug: '' },
];