import { Editor as EditorComponent } from '@tinymce/tinymce-react'
import { TEditorProps } from './types';
import { config } from './config';
import React from 'react';

export const Editor = ({ initialValue, onEditorChange }: TEditorProps) => {
    return (
        <EditorComponent
            initialValue={initialValue}
            init={config}
            onEditorChange={onEditorChange}
        />
    );
};