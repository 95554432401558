import React from "react";
import { IDefaultColorPickerProps } from "./types";

const DefaultColorPicker: React.FC<IDefaultColorPickerProps> = ({
    onHandler,
    defaultValue
}) => {
    const props = defaultValue ? { defaultValue } : {};
    return (
        <input {...props} onChange={onHandler} type="color" />
    );
} 

export default DefaultColorPicker;