import React from 'react'

import * as Table from '../../../../components/Table'
import *as Components from '..'

import { ITableWrapperProps } from './types'

import { STitles } from './static'

const TableWrapper: React.FC<ITableWrapperProps> = ({
  items,
  reloadHandler,
  restaurants,
  sortHandler
}) => {
  return (

    <Table.Table
      type='news'
      titles={STitles}
      sortHandler={sortHandler}
    >

      {items.map((item, index) => (
        <Components.Track
          key={item._id}
          restaurants={restaurants}
          order={index + 1}
          data={item}
          reloadHandler={reloadHandler}
        />
      ))}
    </Table.Table>
  )
}

export default TableWrapper